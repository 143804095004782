
.promoteRank-container {
    position: relative;
    background: #fff;
    width: 100%;
    .promoteRank-top {
        padding: 20px 20px 0;
        display: flex;
        align-items: center;
        .el-date-editor {
            margin-left: 20px;
        }
        .input-with-select {
            width: 360px;
            margin-left: 20px
        }
    }
    .promoteRank-select {
        padding: 0 20px;
        margin-top: 20px;
        display: flex;
        .select-left {
            line-height: 24px;
            margin-right: 12px;
        }
        .select-right {
            width: 1%;
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            .tag-item {
                display: flex;
                align-items: center;
                line-height: 24px;
                padding: 0 8px;
                border-radius: 4px;
                margin-right: 12px;
                cursor: pointer;
                border: 1px solid transparent;
                outline: none;
                transition: all .3s;
                margin-bottom: 10px;
                .iconfont {
                    margin-left: 4px;
                    transition: all .3s;
                }
                &.current {
                    border-color: #1E33E3;
                    color: #1E33E3;
                    .iconfont {
                        transform: rotate(180deg);
                    }
                }
                &:hover {
                    .iconfont {
                        color: #1E33E3;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}
.promoteRank-popover {
    .tag-item-list {
        .second-level {
            display: inline-block;
            padding: 0 10px;
            line-height: 24px;
            cursor: pointer;
            color: #333;
            &:hover, &.current {
                font-weight: 500;
                color: #1E33E3;
            }
        }
    }
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #1E33E3;
    border-color: #1E33E3;
}
/*::v-deep .el-radio-button__inner:hover {*/
/*    color: #1E33E3;*/
/*}*/
// 表格内样式
.listTable {
    width: 100%;
    margin-top: 10px;
    .header-event {
        cursor: pointer;
        .header-icon {
            margin-left: 6px;
            font-size: 12px;
            color: #A0A0A0;
        }
        &.current {
            color: #000;
            .header-icon {
                color: #1E33E3;
            }
        }
    }
    .crease-box {
        .crease-tag {
            color: #666;
            background: #EEEEEE;
            line-height: 28px;
            padding: 0 10px;
            display: inline-block;
            margin-left: 6px;
            white-space: nowrap;
            .iconfont {
                color: #A0A0A0;
                font-size: 12px;
                margin-right: 4px;
            }
        }
        &.current {
            .crease-num {
                font-weight: 700;
            }
            .crease-tag {
                color: #FF606E;
                background: #FFF0F1;
                .iconfont {
                    color: #FF4C5B;
                }
            }
        }
    }
    .goods-wrapper {
        display: flex;
        align-items: center;
        .goods-cover {
            width: 46px;
            height: 46px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .goods-title {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            color: #333;
        }
        &:hover {
            .goods-title {
                color: #1E33E3;
            }
        }
    }
    .goods-rank {
        font-size: 18px;
        font-weight: 500;
    }
}
